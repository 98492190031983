import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/colebemis.com/colebemis.com/src/templates/note-template.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ol {...{
      "start": 0
    }}>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/set-up-changesets"
          }}>{`Set up changesets in your GitHub repository`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a feature branch`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`git checkout -b <branch>
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Push changes to your branch`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`git commit -m "..."
git push
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add a changeset (if the changes should result in a new version)`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`yarn changeset
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a pull request`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`gh pr create
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Merge the pull request into the `}<inlineCode parentName="p">{`main`}</inlineCode>{` (default) branch`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If the pull request contained a changeset, the changesets action will create a `}<a parentName="p" {...{
            "href": "https://github.com/colebemis/demo-component-library/pull/5"
          }}>{`"Version Packages"`}</a>{` pull request`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Continue merging changes into the `}<inlineCode parentName="p">{`main`}</inlineCode>{` (default) branch`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Merge the "Version Packages" pull request to trigger a release`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      